<template>
  <AppPageCard>
    <template #card-title> {{ `Staff Away Assignment` }} </template>

    <div>
      <span
        class="
          text-muted
          font-weight-bold font-size-base
          d-none d-md-inline
          mr-1
        "
      >
        {{ $t("EMPLOYEE.EMP") }}
      </span>
      <span
        class="
          text-dark-50
          font-weight-bolder font-size-base
          d-none d-md-inline
          mr-3
        "
      >
        {{ getTitle() }}
      </span>
      <IndividualEmployeeAwayEntitlementTable
        :is-busy="isBusy"
        :items="items"
        :pagination="pagination"
        @onClickEntitlement="showModal"
      ></IndividualEmployeeAwayEntitlementTable>

      <StaffIndividualAwayAssignmentModal
        :assigned-away="form"
        :is-busy="isBusy"
        @formSubmitted="fetch"
      ></StaffIndividualAwayAssignmentModal>
    </div>
  </AppPageCard>
</template>

<script>
import { mapGetters } from "vuex";
import IndividualEmployeeAwayEntitlementTable from "@/modules/school/components/management/away/entitlement/IndividualEmployeeAwayEntitlementTable";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import StaffIndividualAwayAssignmentModal from "@/modules/school/components/management/away/assignment/staff/StaffIndividualAwayAssignmentModal";

export default {
  name: "StaffIndividualAwayAssignmentPage",
  components: {
    StaffIndividualAwayAssignmentModal,
    AppPageCard,
    IndividualEmployeeAwayEntitlementTable,
  },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    getTitle() {
      return this.$route.query.name;
    },

    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchStaffAwayIndividualEntitlementList", {
          id: this.$route.query.id,
        })
        .finally(() => (this.isBusy = false));
    },

    /**
     *
     * @param data
     */
    showModal(data) {
      console.log("Data ", data);

      this.isBusy = true;
      this.$store
        .dispatch("fetchStaffAwayIndividualEntitlementForm", {
          id: data.id,
        })
        .finally(() => (this.isBusy = false));

      this.$bvModal.show("away-page-assignment-modal");
    },
  },
  computed: {
    ...mapGetters({
      items: "getStaffAwayIndividualEntitlementList",
      pagination: "getStaffAwayIndividualEntitlementListPagination",
      form: "getStaffAwayIndividualEntitlementForm",
    }),
  },
};
</script>

<style scoped></style>
