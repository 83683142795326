<template>
  <div>
    <b-table
      :fields="fields"
      :items="items"
      :busy="isBusy"
      :responsive="true"
      show-empty
      hover
      table-class="table-head-custom table-vertical-center"
      @row-clicked="$emit('row-clicked', $event)"
    >
      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="No staffs registered"
          text-bottom="To register staff, click at the CREATE button"
        ></AppEmptyList>
      </template>

      <!-- A custom formatted header cell for field 'name' -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>

      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <!-- end::: edit-details button -->
      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-details button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="$emit('onClickEntitlement', data.item)"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-details button -->
        </div>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        align="right"
        class="pt-5"
        @change="$emit('onPageChange', $event)"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="employee-page-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
export default {
  name: "IndividualEmployeeAwayEntitlementTable",
  components: { AppEmptyList, AppLoader },
  props: {
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    pagination: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      fields: [
        { key: "index", label: "#" },
        { key: "name", label: "Away Type", sortable: true },
        { key: "abbreviation", label: "Abbreviation" },
        { key: "actions", label: this.$t("DETAILSTABLE.ACT") },
      ],
    };
  },
};
</script>

<style scoped></style>
