<template>
  <b-modal
    id="away-page-assignment-modal"
    :title="$t('ENTITLEMENT.DETAILS')"
    title-class="h4 font-weight-bold"
    lazy
    size="lg"
    hide-footer
  >
    <div class="d-flex flex-column p-5">
      <IndividualEmployeeAwayAssignmentForm
        @onSubmitClicked="submitForm"
      ></IndividualEmployeeAwayAssignmentForm>
    </div>
  </b-modal>
</template>

<script>
import IndividualEmployeeAwayAssignmentForm from "@/modules/school/components/management/away/assignment/IndividualEmployeeAwayAssignmentForm";
export default {
  name: "StaffIndividualAwayAssignmentModal",
  components: { IndividualEmployeeAwayAssignmentForm },
  props: {
    assignedAway: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    submitForm(form) {
      console.log("Form : ", form);

      this.$store
        .dispatch("submitStaffAwayIndividualAssignmentForm", {
          form: {
            id: this.assignedAway.away.school_away.id,
            employee_id: this.assignedAway.employee_id,
            test: "Ayam",
            from_date: form.from_date,
            to_date: form.to_date,
            remark: form.remark,
          },
        })
        .then((res) => {
          console.log(res);
          this.$bvModal.msgBoxOk(
            "Successfully set the individual assignment for staff.",
            {
              centered: true,
            }
          );
          this.$emit("formSubmitted", form);
        })
        .catch((reason) => {
          console.error("Oh no!!", reason);
          this.$bvModal.msgBoxOk(
            "Failed to set the individual assignment for staff.",
            {
              centered: true,
              okVariant: "danger",
            }
          );
        });
    },
  },
};
</script>

<style scoped></style>
